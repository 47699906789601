/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { ViewTaskFilter } from "./CHMActivitiesSnippets";
import {
  CHMFilterType,
  ViewTaskFilterType,
} from "../../../models/CHMActivitiesModels";
import Label from "../../../components/Label";
import CHMFormdetails from "./CHMFormdetails";
import CHMTable from "../../../components/CHMTableComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  getCHMExcelData,
  getCHMInfoAPICall,
  getCHMViewMapData,
  getStatesDataAPICall,
} from "../../../api/chmActivityAPI";
import CPagination from "../../../components/CPagination";
import {
  handleCHMPaginationDataSlice,
  startLoading,
} from "../../../redux/slices/globalSlice";
import { DEFAULT_OPTION } from "../../../utils/Constant";
import DataNotFound from "../../../components/DataNotFoundComponents";
import CHMActivityDashboardMap from "./CHMActivityDashboardMap";
import {
  handleCHMDashboardViewMapDataSlice,
  handleCHMExcelDownloadDataSlice,
  handleCHMIdsDataSlice,
} from "../../../redux/slices/chmActivitySlice";
import "../../activities/chmActivities/CHMActivities.css";
import { toast } from "react-toastify";
import SideNav from "../../../components/SideNav";
import { dateConverter, dateConverterWithoutAddingDays } from "../../../utils/Util";

const CHMActivities = () => {
  const [selectedFilter, setSelectedFilter] = useState<CHMFilterType>({
    state: DEFAULT_OPTION,
    district: DEFAULT_OPTION,
    mandal: DEFAULT_OPTION,
    village: DEFAULT_OPTION,
    season: DEFAULT_OPTION,
    cropName: DEFAULT_OPTION,
    search: DEFAULT_OPTION,
    fromDate: undefined,
    toDate: undefined,
    year: DEFAULT_OPTION,
  });
  const [viewDetails, setViewDetails] = useState(false);
  const [currentCount, setCurrentCount] = useState(10);
  const [showMap, setShowMap] = useState(false);
  const updatePageStore = useSelector((state: any) => state.global);
  const dispatch: any = useDispatch();

  const viewDetailsFunc = () => {
    setViewDetails(true);
  };

  const { storeCHMPaginationData } = useSelector((state: any) => state.global);

  const {
    storeCHMDashboardData,
    storeCHMIds,
    storeDashboardViewMapData,
    storeCHMExcelData,
  } = useSelector((state: any) => state.chmActivity);

  useEffect(() => {
    if (showMap) {
      dispatch(
        getCHMViewMapData(
          selectedFilter.state.value,
          selectedFilter.district.value,
          selectedFilter.mandal.value,
          selectedFilter.village.value,
          selectedFilter.season.value,
          selectedFilter.cropName.value,
          selectedFilter.search.value,
          selectedFilter.fromDate !== undefined
            ? dateConverter(selectedFilter.fromDate?.toString(), "fromDate")
            : undefined,
          selectedFilter.toDate !== undefined
            ? dateConverter(selectedFilter.toDate?.toString(), "toDate")
            : undefined,
          selectedFilter.year.value
        )
      );
    } else {
      chmActivityDashoard();
    }
  }, [dispatch, storeCHMPaginationData, selectedFilter]);

  const chmActivityDashoard = () => {
    const { limit, offset, selectedLimit } = storeCHMPaginationData;
    dispatch(
      getCHMInfoAPICall(
        limit,
        offset,
        selectedFilter.state.value,
        selectedFilter.district.value,
        selectedFilter.mandal.value,
        selectedFilter.village.value,
        selectedFilter.season.value,
        selectedFilter.cropName.value,
        selectedFilter.search.value,
        selectedFilter.fromDate !== undefined
          ?  selectedFilter.fromDate?.toString() === selectedFilter.toDate?.toString() ? dateConverterWithoutAddingDays(selectedFilter.fromDate?.toString(), "fromDate") : dateConverter(selectedFilter.fromDate?.toString(), "fromDate")
          : undefined,
        selectedFilter.toDate !== undefined
          ? selectedFilter.fromDate?.toString() === selectedFilter.toDate?.toString() ? dateConverterWithoutAddingDays(selectedFilter.toDate?.toString(), "toDate") : dateConverter(selectedFilter.toDate?.toString(), "toDate")
          : undefined,
        selectedFilter.year.value
      )
    );
  };

  const changePaginationLimit = (value: number) => {
    dispatch(
      handleCHMPaginationDataSlice({
        ...storeCHMPaginationData,
        offset: 0,
        limit: value,
        selectedLimit: value,
      })
    );
  };

  const prevPage = (value: number) => {
    if (
      value >= storeCHMPaginationData.selectedLimit &&
      storeCHMPaginationData.offset > 0
    ) {
      dispatch(
        handleCHMPaginationDataSlice({
          ...storeCHMPaginationData,
          limit:
            storeCHMPaginationData.limit -
              storeCHMPaginationData.selectedLimit <
            25
              ? storeCHMPaginationData.selectedLimit
              : storeCHMPaginationData.limit -
                storeCHMPaginationData.selectedLimit,
          offset:
            storeCHMPaginationData.offset -
            storeCHMPaginationData.selectedLimit,
        })
      );
    }
  };
  const nextPage = (value: number) => {
    if (value < storeCHMDashboardData.count) {
      dispatch(
        handleCHMPaginationDataSlice({
          ...storeCHMPaginationData,
          offset: storeCHMPaginationData.limit,
          limit: value + storeCHMPaginationData.selectedLimit,
        })
      );
    }
  };

  const viewMap = async (value: String) => {
    if (value === "Open") {
      if (
        selectedFilter.state.value ||
        selectedFilter.district.value ||
        selectedFilter.mandal.value ||
        selectedFilter.village.value ||
        selectedFilter.season.value ||
        selectedFilter.cropName.value ||
        selectedFilter.search.value ||
        (selectedFilter.fromDate && selectedFilter.toDate) ||
        selectedFilter.year.value
      ) {
        dispatch(
          getCHMViewMapData(
            selectedFilter.state.value,
            selectedFilter.district.value,
            selectedFilter.mandal.value,
            selectedFilter.village.value,
            selectedFilter.season.value,
            selectedFilter.cropName.value,
            selectedFilter.search.value,
            selectedFilter.fromDate !== undefined
              ? dateConverter(selectedFilter.fromDate?.toString(), "fromDate")
              : undefined,
            selectedFilter.toDate !== undefined
              ? dateConverter(selectedFilter.toDate?.toString(), "toDate")
              : undefined,
            selectedFilter.year.value
          )
        );
        setShowMap(!showMap);
      } else {
        dispatch(handleCHMDashboardViewMapDataSlice({}));
        toast.error("Select Filters to view the map");
      }
    } else {
      chmActivityDashoard();
      dispatch(handleCHMDashboardViewMapDataSlice({}));
      setShowMap(!showMap);
    }
  };

  const downloadExcel = () => {
    // let listOfCHMIds: any = [];
    // for (let index = 0; index < storeCHMDashboardData.data.length; index++) {
    //   listOfCHMIds.push(storeCHMDashboardData.data[index].chmID);
    // }
    if (
      selectedFilter.state.value ||
      selectedFilter.district.value ||
      selectedFilter.mandal.value ||
      selectedFilter.village.value ||
      selectedFilter.season.value ||
      selectedFilter.cropName.value ||
      selectedFilter.search.value ||
      (selectedFilter.fromDate && selectedFilter.toDate) ||
      selectedFilter.year.value
    ) {
      dispatch(
        getCHMExcelData(
          selectedFilter.state.value,
          selectedFilter.district.value,
          selectedFilter.mandal.value,
          selectedFilter.village.value,
          selectedFilter.season.value,
          selectedFilter.cropName.value,
          selectedFilter.search.value,
          selectedFilter.fromDate !== undefined
            ? dateConverter(selectedFilter.fromDate?.toString(), "fromDate")
            : undefined,
          selectedFilter.toDate !== undefined
            ? dateConverter(selectedFilter.toDate?.toString(), "toDate")
            : undefined,
          selectedFilter.year.value
        )
      );
    } else {
      toast.error("Select Filters to view the map");
    }
  };

  useEffect(() => {
    if (storeCHMExcelData.statusCode === 1 && storeCHMExcelData.data) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${storeCHMExcelData.data}`;
      const downloadLink = document.createElement("a");
      const fileName = `${Date.now()}.xlsx`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      toast.success("File Downloaded Successfully");
      dispatch(handleCHMExcelDownloadDataSlice({}));
    }
  }, [storeCHMExcelData.statusCode === 1]);

  return (
    <>
      {updatePageStore.updatePageStore ? (
        <CHMFormdetails />
      ) : (
        <>
          <Label name="Apply Filters" />
          <ViewTaskFilter onApplyFilters={setSelectedFilter} />
          {showMap ? (
            storeDashboardViewMapData?.data?.length > 0 ? (
              <CHMActivityDashboardMap
                storeCHMIds={storeCHMIds}
                viewMap={viewMap}
              />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    viewMap("Close");
                  }}
                >
                  <img
                    style={{ height: "25px", width: "25px" }}
                    src={require("../../../assets/img/backarrow.svg").default}
                    alt=""
                  />
                  <p style={{ color: "#537C5B", fontWeight: 600 }}>
                    {" "}
                    Back to List{" "}
                  </p>
                </div>
                {storeDashboardViewMapData.statusCode === 0 && <DataNotFound />}
              </>
            )
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    color: "#537C5B",
                    fontWeight: 600,
                  }}
                >
                  Total Records{" "}
                  {storeCHMDashboardData.count > storeCHMPaginationData.limit
                    ? storeCHMPaginationData.limit
                    : storeCHMDashboardData.count}
                  /{storeCHMDashboardData.count}
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="excel-button" onClick={() => viewMap("Open")}>
                    <img
                      src={
                        require("../../../assets/img/mapviewimage.svg").default
                      }
                      className="excel-button-img"
                    />
                    Map View
                  </p>
                  <p
                    className="excel-button"
                    onClick={() => {
                      downloadExcel();
                    }}
                  >
                    <img
                      src={require("../../../assets/img/xlsLogo.svg").default}
                      className="excel-button-img"
                    />
                    Export XLS
                  </p>
                </div>
              </div>
              {storeCHMDashboardData?.statusCode === 1 && (
                <CHMTable CHMDashboardData={storeCHMDashboardData} />
              )}

              {storeCHMDashboardData?.statusCode === 0 && <DataNotFound />}

              <CPagination
                storeCHMDashboardData={storeCHMDashboardData}
                storeCHMPaginationData={storeCHMPaginationData}
                changePaginationLimit={changePaginationLimit}
                nextPage={nextPage}
                prevPage={prevPage}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CHMActivities;
