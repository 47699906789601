import { Buffer } from "buffer";

// entry path
//TODO
const BASE_PATH: string = "";

/* host names */
const UAT_HOST: string = "uat01.kshema.co";
const POC_HOST: string = "poc01.kshema.co";
const CROPS_POC_HOST:string="poc02.kshema.co";
const CROPS_UAT_HOST:string="uat02.kshema.co";
const PROD_HOST: string = "prod01.omdc.co.in";

/* Change this to true to use uat */
const isFromUAT: boolean = true;

const CURRENT_HOST: string = isFromUAT ? UAT_HOST : POC_HOST;
const CROPS_HOST:string=isFromUAT?CROPS_UAT_HOST:CROPS_POC_HOST;
// const CURRENT_HOST: string = PROD_HOST;

// environment
const CURRENT_PATH: string = "https://" + CURRENT_HOST + "/" + BASE_PATH;
const CROPS_PATH:string="https://"+CROPS_HOST+"/"+ BASE_PATH;
// types of methods
export const method: {
  DELETE: string;
  POST: string;
  GET: string;
  PUT: string;
  PATCH: string;
  OPTIONS: string;
} = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
  OPTIONS: "OPTIONS",
};

//TODO
const credentials = {
  Username: "",
  Password: "i luv Kshema",
};

// api paths
export const paths = {
  // base URL
  BASE_URL: CURRENT_PATH,
  CROPS_URL:CROPS_PATH,

  // Encode credentials in Base64
  basicAuthString: Buffer.from(
    `${credentials.Username}:${credentials.Password}`
  ).toString("base64"),

  AUTHENTICATION_ENDPOINT: "REST/authentication",
  CHM_STATES_DATA: "chmops/chmOps/get-states",
  CHM_DISTRICTS_DATA: "chmops/chmOps/get-districts",
  CHM_MANDAL_DATA:"chmops/chmOps/get-mandals",
   CHM_LEVEL4_DATA: "chmops/chmOps/getLevel4Data",
  CHM_LEVEL5_DATA: "chmops/chmOps/getLevel5Data",
  CHM_VILLAGES_DATA: "chmops/chmOps/get-villages",
  CHM_SEASON_DATA: "chmops/chmOps/get-seasons",
  CHM_CROPS_DATA: "external-services/crop",
  CHM_GET_CHM_INFO: "chmops/chmOps/chm-info",
  CHM_GET_CHM_INFO_BY_ID: "chmops/chmOps/chm-form-data",
  GIS_COMPUTEINDEX: "gischm/computeindex",
  GIS_CHM_CHART: "gischm/fetchdata",
  CHM_IMAGES: "chmops/chmOps/get-images",
  CHM_VIEWMAP_POLYGON_VALUES: "chmops/chmOps/view-map-data",
  CHM_EXCEL_DOWNLOAD_DATA: "chmops/chmOps/excel-download",
  CHM_EXCEL_SHEET_UPLOAD: "chmops/chmOps/import-excel",
  CHM_SURVEYOR_DATA: "chmops/chmOps/get-surveyors-data",
  CHM_ASSIGNE_SURVEYER: "chmops/chmOps/assign-surveyor",
  CHM_ERORLOGS: "chmops/chmOps/error-logs",
};

export const METHOD = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
};
