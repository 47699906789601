/* eslint-disable no-lone-blocks */
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { DEFAULT_OPTION } from "../../../utils/Constant";
import SelectBox from "../../../components/SelectBox";
import {
  getDistrictsDataAPICall,
  getStatesDataAPICall,
  getMandalDataAPICall,
  uploadCHMExcelSheet,
  getCHMSurveyorData,
  CHMDownloadErrorLogs,
} from "../../../api/chmActivityAPI";
import { useDispatch, useSelector } from "react-redux";
import { FormUtils } from "../../../utils/FormUtils";
import Label from "../../../components/Label";
import FileInput from "../../../components/FileInput";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { handleCHMExcelUploadStatusDataSlice } from "../../../redux/slices/chmActivitySlice";
import Multiselect from "multiselect-react-dropdown";

// Multi Select Dropdown custom styles
const customStyles = {
  multiselectContainer: {
    // To change css for multiselect (Width,height etc..)
    backgroundColor: "#F1F4F8",
  },
  searchBox: {
    // To change search box element look
  },
  chips: {
    // To change css chips(Selected options)
  },
  optionContainer: {
    // To change css for option container
  },
  option: {
    // To change css for dropdown options
  },
  groupHeading: {
    // To change group heading style
  },
};

export const MasterTaskFilter = ({ onApplyFilters, filterRawData }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fileName, setFileName] = useState("Browse File");
  const [chmFilter, setCHMFilter] = useState<any>({
    state: DEFAULT_OPTION,
    district: DEFAULT_OPTION,
    mandal: DEFAULT_OPTION,
    surveyorName: DEFAULT_OPTION,
    file: {},
  });

  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(getStatesDataAPICall());
    dispatch(getCHMSurveyorData(""));
  }, [dispatch]);

  const clearFields = () => {
    setCHMFilter({
      ...chmFilter,
      state: DEFAULT_OPTION,
      district: DEFAULT_OPTION,
      mandal: DEFAULT_OPTION,
      surveyorName: DEFAULT_OPTION,
      file: {},
    });
  };
  const {
    storeCHMGetStatesData,
    storeCHMGetDistrictsData,
    storeCHMGetMandalData,
    storeCHMExcelUploadStatus,
    storeCHMExcelUploadDetails,
    storeCHMSurveyorData,
  } = useSelector((state: any) => state.chmActivity);

  const surveyorsData = FormUtils.convertSurveyorDataList(
    storeCHMSurveyorData.data ?? []
  );

  const statesData = FormUtils.convertDataList(
    storeCHMGetStatesData.data ?? []
  );

  const districtsData = FormUtils.convertDataList(
    storeCHMGetDistrictsData.data ?? []
  );

  const mandalData = FormUtils.convertDataList(
    storeCHMGetMandalData.data ?? []
  );
  
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 3,
  };

  const onchangeDropdown = (label: String, event: any) => {
    switch (label) {
      case "State":
        dispatch(getDistrictsDataAPICall(event.value));
        break;
      case "District":
        {
          if (typeof chmFilter.state.value === "number") {
            dispatch(getMandalDataAPICall(chmFilter.state.value, event.value));
          }
        }
        break;
      default:
        break;
    }
  };

  const uploadFile = (data: any) => {
    setFileName(data.name);
    setCHMFilter({
      ...chmFilter,
      file: data,
    });
    setTimeout(() => {
      setIsOpen(true);
    }, 100);
  };

  const uploadExcelFile = () => {
    setTimeout(() => {
      dispatch(handleCHMExcelUploadStatusDataSlice(1));
      dispatch(uploadCHMExcelSheet("goutham", chmFilter.file));
    }, 100);
  };

  const cancelExcelFile = () => {
    setTimeout(() => {
      setIsOpen(false);
      setCHMFilter({
        ...chmFilter,
        file: {},
      });
      setFileName("Browse File");
      dispatch(handleCHMExcelUploadStatusDataSlice(0));
    }, 100);
  };

  const downloadErrorLogs = (fileId: number) => {
    dispatch(CHMDownloadErrorLogs(fileId));
    cancelExcelFile();
  };

  const [selectedSurveyors, setSelectedSurveyors] = useState<any[]>([]);
  const handleSelect = (selectedList: any[]) => {
    setSelectedSurveyors(selectedList);
    setCHMFilter({
      ...chmFilter,
      surveyorName: selectedList.map((item) => item.value),
    });
  };
  const handleRemove = (selectedList: any[]) => {
    setSelectedSurveyors(selectedList);
    setCHMFilter({
      ...chmFilter,
      surveyorName: selectedList.map((item) => item.value),
    });
  };
  const clearSelections = () => {
    setSelectedSurveyors([]);
  };

  const uploadValidExcel = (storeCHMExcelUploadDetails.statusMessage) === 'Upload Valid Excel';

  return (
    <>
      <Label name="Apply Filters" />
      <div className="border rounded ps-3 pt-2 pe-3 pb-3 mb-3">
        <Row>
          <Col xs={12} md={6} lg={3}>
            <SelectBox
              label="State"
              options={statesData}
              className="css-select-bg"
              value={chmFilter.state}
              onChange={(event: any) => {
                if (event != null) {
                  setCHMFilter({
                    ...chmFilter,
                    state: event,
                    district: DEFAULT_OPTION,
                    mandal: DEFAULT_OPTION,
                  });

                  onchangeDropdown("State", event);
                } else {
                  setCHMFilter({
                    ...chmFilter,
                    state: DEFAULT_OPTION,
                    district: DEFAULT_OPTION,
                    mandal: DEFAULT_OPTION,
                  });
                }
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <SelectBox
              disabled={chmFilter.state.label === "Select"}
              label="District"
              value={chmFilter.district}
              options={districtsData}
              onChange={(event: any) => {
                if (event != null) {
                  setCHMFilter({
                    ...chmFilter,
                    district: event,
                    mandal: DEFAULT_OPTION,
                  });

                  onchangeDropdown("District", event);
                } else {
                  setCHMFilter({
                    ...chmFilter,
                    district: DEFAULT_OPTION,
                    mandal: DEFAULT_OPTION,
                  });
                }
              }}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <SelectBox
              disabled={chmFilter.district.label === "Select"}
              label="Block"
              options={mandalData}
              className="css-select-bg"
              value={chmFilter.mandal}
              onChange={(event: any) => {
                if (event != null) {
                  setCHMFilter({
                    ...chmFilter,
                    mandal: event,
                  });
                  onchangeDropdown("mandal", event);
                } else {
                  setCHMFilter({
                    ...chmFilter,
                    mandal: DEFAULT_OPTION,
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FileInput
              fileName={fileName}
              label="Upload Data File"
              uploadFile={uploadFile}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <p
              style={{
                margin: 3,
                fontSize: 12,
                fontWeight: 600,
                color: "#9F9F9F",
              }}
            >
              {" "}
              Assigne To{" "}
            </p>
            <Multiselect
              displayValue="label"
              style={customStyles}
              className="multiSelect"
              closeOnSelect={true}
              selectedValues={selectedSurveyors}
              onSelect={handleSelect}
              onRemove={handleRemove}
              options={surveyorsData}
              showCheckbox
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col xs={12} md={6} lg={3} className="d-flex align-items-start mb-2">
            {chmFilter.mandal.value !== null &&
            chmFilter.surveyorName.value !== null ? (
              <button
                style={{ marginLeft: 0 }}
                className="button"
                onClick={() => {
                  onApplyFilters(chmFilter);
                  clearFields();
                  clearSelections();
                }}
              >
                Assign
              </button>
            ) : (
              <button
                style={{ backgroundColor: "#dddddd", marginLeft: 0 }}
                className="button"
              >
                Assign
              </button>
            )}

            {selectedSurveyors.length > 0 ? (
              <button
                className="button"
                onClick={() => {
                  setSelectedSurveyors([]);
                  setCHMFilter({
                    ...chmFilter,
                    state: DEFAULT_OPTION,
                    district: DEFAULT_OPTION,
                    mandal: DEFAULT_OPTION,
                  });
                }}
              >
                Reset
              </button>
            ) : (
              <button style={{ backgroundColor: "#dddddd" }} className="button">
                Reset
              </button>
            )}
          </Col>
        </Row>
      </div>

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {storeCHMExcelUploadStatus === 0 && (
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure!
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 4 }}>
                you want to upload this file
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 4, fontWeight: 600 }}
              >
                filename : {chmFilter.file.name}
              </Typography>
              <div style={{ marginTop: 14 }}>
                <button
                  className="button"
                  style={{ marginLeft: 0 }}
                  onClick={() => {
                    uploadExcelFile();
                  }}
                >
                  OK
                </button>
                <button
                  className="button"
                  onClick={() => {
                    cancelExcelFile();
                  }}
                >
                  CANCEL
                </button>
              </div>
            </Box>
          )}

          {storeCHMExcelUploadStatus === 1 && (
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Wait!
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                    <CircularProgress size={25} color="success" />
                  </Stack>
                  File Uploading.....
                </div>
              </Typography>
            </Box>
          )}

          {storeCHMExcelUploadStatus === 2 && (
            <Box sx={style}>
              <div
                style={{
                  height: uploadValidExcel ? 50 : 250,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: uploadValidExcel ? "center" : "space-between",
                  alignItems: uploadValidExcel ? "center" : ''
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {storeCHMExcelUploadDetails.statusMessage}
                  {uploadValidExcel ? <div style={{ marginTop: 14 }}>
                    <button
                      className="button"
                      style={{ width: "100%", marginLeft: 0 }}
                      onClick={() => {
                        cancelExcelFile();
                      }}
                    >
                      CLOSE
                    </button>
                  </div>: ''}
                </Typography>

                {!uploadValidExcel ? <div>
                  <div>
                    <Typography variant="subtitle1" gutterBottom>
                      <span style={{ fontWeight: 600 }}> File ID : </span>
                      {storeCHMExcelUploadDetails?.data?.fileId ?? "N/A"}
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                      <span style={{ fontWeight: 600 }}>
                        {" "}
                        Success Records :
                      </span>
                      {storeCHMExcelUploadDetails?.data?.sucessRecords ?? "N/A"}
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                      <span style={{ fontWeight: 600 }}>
                        {" "}
                        Failure Records :
                      </span>
                      {storeCHMExcelUploadDetails?.data?.failureRecords ??
                        "N/A"}
                    </Typography>
                  </div>

                  <div style={{ marginTop: 14 }}>
                    <button
                      className="button"
                      style={{ width: "100%", marginLeft: 0 }}
                      onClick={() => {
                        cancelExcelFile();
                      }}
                    >
                      CLOSE
                    </button>
                  </div>
                  <div style={{ marginTop: 14 }}>
                    <button
                      className="button"
                      style={{ width: "100%", marginLeft: 0 }}
                      onClick={() => {
                        downloadErrorLogs(
                          storeCHMExcelUploadDetails?.data?.fileId
                        );
                      }}
                    >
                      Download Error Logs
                    </button>
                  </div>
                </div>: ''}
              </div>
            </Box>
          )}
        </>
      </Modal>
    </>
  );
};
