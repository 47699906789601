/* eslint-disable no-lone-blocks */
import axios, { AxiosRequestConfig } from "axios";
import { paths } from "./ServiceConstants";
import { getStorageDatum } from "./SettersAndGetters";
import { Constants } from "../utils/Constant";
import DebugUtils from "../utils/DebugUtils";
import { toast } from "react-toastify";
import path from "path";

const ServicesManagerAPICall = {
  request: async (
    methodType: string,
    endpoint: string,
    data?: any,
    hasAuthToken: boolean = true,
    multipart: boolean = false,
    isCrop: boolean = false,
  ) => {
    let queryParams: string = "?";
    let url: any;
    let authenticationDatum: any;

    if (hasAuthToken) {
      authenticationDatum = "";
      //TODO
      // (await getStorageDatum(Constants.AUTHENTICATION_DATUM)) ?? {};
    }

    const config: AxiosRequestConfig = {
      method: "",
      url: "",
      headers: hasAuthToken
        ? {
            // Authorization: `Bearer ${authenticationDatum[0].token ?? ""}`,
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJnb3V0aGFtIiwiaWF0IjoxNzE5MjExODA5fQ.HcTqqzguypZoeX1omcx-fvvad3V-sAZ5f1LYqaYX0fc",
            "Content-Type": multipart
              ? "multipart/form-data"
              : "application/json",
          }
        : {
            Authorization: `Basic ${paths.basicAuthString}`,
            "Content-Type": "application/json",
          },
    };

    try {
      switch (methodType) {
        case "GET":
          {
            if (data) {
              for (const key in data) {
                queryParams += `${key}=${data[key]}&`;
              }
              url = isCrop===true?`${paths.CROPS_URL}${endpoint}${queryParams}`:`${paths.BASE_URL}${endpoint}${queryParams}`;
            } else {
              url = isCrop===true?`${paths.CROPS_URL}${endpoint}`:`${paths.BASE_URL}${endpoint}`;
            }
            config.method = `${methodType.toLowerCase()}`;
            config.url = url;
          }
          break;

        case "POST":
        case "PUT":
        case "PATCH":
          {
            config.method = `${methodType.toLowerCase()}`;
            config.url = isCrop===true?`${paths.BASE_URL}${endpoint}`:`${paths.BASE_URL}${endpoint}`;
            config.data = data;
          }
          break;

        case "DELETE":
        case "OPTIONS":
          {
            config.method = `${methodType.toLowerCase()}`;
            config.url = `${paths.BASE_URL}${endpoint}`;
          }
          break;

        default:
          throw new Error(`Unsupported method type: ${methodType}`);
      }

      const response = await axios(config);
      if (response.status === 200) {
        return response.data ?? {};
      }
    } catch (error: any) {
      toast.error("Unable fetch the data, Please try again.");
      return error.message;
    }
  },
};

export default ServicesManagerAPICall;
