import { Row, Col } from "reactstrap";
import { LabelInput } from "../../../components/LabelInput";
import "./CHMActivities.css";
import { useDispatch } from "react-redux";
import { handleUpdatePageSlice } from "../../../redux/slices/globalSlice";
import { useEffect, useState } from "react";
import Label from "../../../components/Label";
import { gisComputeIndex } from "../../../api/chmActivityAPI";

/** IECActivitiesFormDetails Component */
export const CHMActivitiesFormDetails = ({ storeCHMDIdData }: any) => {
  const dispatch: any = useDispatch();
  const labelList: any[] = [
    {
      "Survey Date": storeCHMDIdData.data[0]?.surveyDate?.split("T")[0]?.split("-")?.reverse()?.join("-"),
      "Crop Pattern ": storeCHMDIdData.data[0]?.cropPattern,
      "Application Number": storeCHMDIdData.data[0]?.appliationId,
      "Mixed Crop Names" : storeCHMDIdData.data[0]?.mixedCrops,
      "Irrigation type": storeCHMDIdData.data[0]?.irrigationType,
      Year: storeCHMDIdData.data[0]?.year,
      "Crop Stage": storeCHMDIdData?.data[0]?.cropStageName ,
      Season: storeCHMDIdData.data[0]?.season,
      "Farmer Name": storeCHMDIdData?.data[0]?.farmerName,
      State: storeCHMDIdData.data[0]?.stateName,
      "Farmer Mobile Number": storeCHMDIdData?.data[0]?.farmerMobileNumber,
      District: storeCHMDIdData.data[0]?.districtName,
      "Nature of the crop": storeCHMDIdData.data[0]?.natureOfTheCrop,
      "Tehsil/Block": storeCHMDIdData.data[0]?.mandalName,
      "Sowing Date": storeCHMDIdData.data[0].sowingDate?.split("T")[0]?.split("-")?.reverse().join("-"),
      Village: storeCHMDIdData.data[0]?.villageName,
      "Expected Harvesting Date": storeCHMDIdData.data[0].harvestDate?.split("T")[0]?.split("-")?.reverse()?.join("-"),
      "Survey Number": storeCHMDIdData.data[0].surveyNumber,
      "Damage by Pest/Disease": String(storeCHMDIdData.data[0].damaged),
      "Crop Name as per Application":
        storeCHMDIdData.data[0].cropNameAsPerApplication,
      "Level of Damage": storeCHMDIdData.data[0].damagedStatus,
      "Crop On field ": storeCHMDIdData.data[0].cropNameAsPerField,
      "Comments":storeCHMDIdData.data[0].comments,
    },
  ];

  if(storeCHMDIdData.data[0].cropPattern === 'Single Crop') delete labelList[0]["Mixed Crop Names"]

  useEffect(() => {
    dispatch(gisComputeIndex(storeCHMDIdData.data[0].appliationId));
  }, [dispatch]);

  return (
    <Row>
      {Object.keys(labelList[0]).map((value: string, index: number) => {
        return (
          <Col xs={12} md={6} key={`value-${index}`}>
            <LabelInput
              fixedLabel={value}
              placeHolder={labelList[0][value] ? labelList[0][value] : "N/A"}
            />
          </Col>
        );
      })}
    </Row>
  );
};
