import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polygon,
  LoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import * as geolib from "geolib";
import { GOOGLE_MAP_KEY } from "../../../utils/Config";
import { isWithin30Meters, regionContainingPoints } from "../../../utils/Util";
import "../../../assets/style/App.css";
import { useSelector } from "react-redux";

const mapContainerStyle = {
  width: "100%",
  height: "526px",
  borderRadius: 8,
};
const CHMActivityDashboardMap = ({ viewMap }: any) => {
  const { storeDashboardViewMapData } = useSelector(
    (state: any) => state.chmActivity
  );

  const [region, setRegion] = useState<any>({});
  const [polygons, setPolygons] = useState<any>([]);
  const [infoWindow, setInfoWindow] = useState(null);

  useEffect(() => {
    // After the map has been initialized...
    const style = document.createElement("style");
    style.textContent = `
  /* Your CSS rules */
  .gm-ui-hover-effect {
  opacity: 1;
  display: none !important;
}

.gm-style .gm-style-iw-d {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: none !important;
}

.gm-style .gm-style-iw-c {
  position: absolute !important;
  overflow: hidden !important;
  transform: translate3d(-50%, -100%, 0) !important;
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3) !important;
  padding: 0px !important;
}

.gm-style .gm-style-iw-d {
  overflow: hidden !important;
  padding-top: 0px !important;
  margin: 0px !important;
  height: auto;
  width: auto;
}

.gm-style-iw-chr {
  display: none;
}
  /* More rules... */
`;
    document.head.append(style);

    let mapAndMarkerView = [];
    for (
      let index = 0;
      index < storeDashboardViewMapData.data.length;
      index++
    ) {
      if (storeDashboardViewMapData.data[index].polygon) {
        let polygon = storeDashboardViewMapData.data[index].polygon
          .replace("POLYGON((", "")
          .replace("))", "")
          .split(",");

        let polygonCentroid = storeDashboardViewMapData.data[
          index
        ]?.polygonCentroid
          .replace("POINT(", "")
          .replace(")", "")
          .split(" ");

        if (polygon != null && polygonCentroid != null) {
          let polygonCords = [];
          for (let index = 0; index < polygon.length; index++) {
            polygonCords.push({
              lat: Number(polygon[index].split(" ")[1]),
              lng: Number(polygon[index].split(" ")[0]),
            });
          }

          mapAndMarkerView.push({
            data: storeDashboardViewMapData.data[index],
            polygon: polygonCords,
            userlocation: {
              lat: Number(storeDashboardViewMapData.data[index].lat),
              lng: Number(storeDashboardViewMapData.data[index].lon),
            },
            centroid: {
              lat: Number(
                storeDashboardViewMapData.data[index]?.polygonCentroid
                  ?.split(" ")[1]
                  ?.replace(")", "")
              ),
              lng: Number(
                storeDashboardViewMapData.data[index]?.polygonCentroid
                  .split(" ")[0]
                  ?.replace("POINT(", "")
              ),
            },
            isValid: distanceCalculate(polygonCords, {
              lat: Number(storeDashboardViewMapData.data[index].lat),
              lng: Number(storeDashboardViewMapData.data[index].lon),
            }),
          });
          polygonCords = [];
        }
      }
    }

    const regionValue = regionContainingPoints(mapAndMarkerView);
    setRegion(regionValue);

    setPolygons(mapAndMarkerView);
    mapAndMarkerView = [];
  }, [storeDashboardViewMapData]);

  const distanceCalculate = (unqPolygon: any, cords: any) => {
    // Calculate the distance from the point to each vertex of the polygon
    const distances = unqPolygon.map((vertex: any) =>
      geolib.getDistance(cords, vertex)
    );
    // The distance from the point to the polygon is the minimum of these distances
    const minDistance = Math.min(...distances);

    if (minDistance <= 30) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY}>
      <div style={{ marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            viewMap("Close");
          }}
        >
          <img
            style={{ height: "25px", width: "25px" }}
            src={require("../../../assets/img/backarrow.svg").default}
            alt=""
          />
          <p style={{ color: "#537C5B", fontWeight: 600 }}> Back to List </p>
        </div>

        <GoogleMap
          key={"Dashboard-map"}
          mapContainerStyle={mapContainerStyle}
          zoom={5}
          center={region}
          options={{
            mapTypeId: "satellite",
            zoomControl: true, // disable zoom control button
            mapTypeControl: false, // disable map type control
            scaleControl: true, // disable scale control
            streetViewControl: false, // disable street view control
            rotateControl: false, // disable rotate control
            fullscreenControl: true, // disable fullscreen control
            // gestureHandling: "none", // disable all panning/zooming gestures
            //   disableDefaultUI: true, // disable all default UI
          }}
        >
          {polygons.map((each: any, index: number) => (
            <div key={`polygon-${index}`}>
              <Polygon
                paths={each.polygon}
                options={{
                  fillColor: "#61322f",
                  fillOpacity: 0.9,
                  strokeColor: "black",
                  // strokeOpacity: 0.8,
                  strokeWeight: 1,
                }}
              />

              <Marker
                position={each.centroid}
                icon={{
                  url: each.isValid
                    ? require("../../../assets/img/marker.svg").default
                    : require("../../../assets/img/marker_red.svg").default,
                  // scaledSize: new window.google.maps.Size(50, 50),
                }}
                onMouseOver={() => {
                  if (infoWindow === null) {
                    setInfoWindow(each.centroid);
                  }
                }}
                onMouseOut={() => {
                  if (infoWindow !== null) {
                    setInfoWindow(null);
                  }
                }}
              >
                {each.centroid === infoWindow && (
                  <InfoWindow
                    key={`infowindow-${index}`}
                    position={each.centroid}
                    onCloseClick={() => {
                      setInfoWindow(null);
                    }}
                  >
                    <div
                      style={{
                        background: "#0D2316",
                        height: "87px",
                        width: "157px",
                        overflow: "none !important",
                        padding: 8,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <p style={{ color: "#FFFFFF", padding: 0, margin: 0 }}>
                        Survey Number :
                        <span style={{ fontWeight: 600 }}>
                          {each.data.surveyNo}
                        </span>
                      </p>
                      <p style={{ color: "#FFFFFF", padding: 0, margin: 0 }}>
                        Crop :
                        <span style={{ fontWeight: 600 }}>
                          {each.data.cropAsPerApplication}
                        </span>
                      </p>
                      <p style={{ color: "#FFFFFF", padding: 0, margin: 0 }}>
                        Village :
                        <span style={{ fontWeight: 600 }}>
                          {each.data.villageName}
                        </span>
                      </p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </div>
          ))}
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default CHMActivityDashboardMap;
