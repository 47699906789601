import { Table } from "reactstrap";
import "./CHMTableComponents.css";
import { handleUpdatePageSlice } from "../redux/slices/globalSlice";
import { useDispatch } from "react-redux";
import { getCHMInfoByIdAPICall } from "../api/chmActivityAPI";

const CHMTable = ({ CHMDashboardData }: any) => {
  const dispatch: any = useDispatch();

  const getEachCHMInfo = (chmId: string, cropStageId: number) => {
    dispatch(getCHMInfoByIdAPICall(chmId, cropStageId));
    dispatch(handleUpdatePageSlice(true));
  };

  return (
    <Table striped hover responsive style={{ marginBottom: "53px" }}>
      <thead>
        <tr className="aligne-center">
          <th>CHM ID</th>
          <th>State</th>
          <th>District</th>
          <th>Block</th>
          <th>Crop Stage</th>
          <th>Crop Name As per Application</th>
          <th>Crop Name On field</th>
          <th>CHM Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {CHMDashboardData &&
          CHMDashboardData.data?.map((each: any, index: any) => {
            return (
              <tr key={`CHMTable-${index}`} className="aligne-center">
                <td>{each.chmID}_{each.cropStageId}</td>
                <td>{each.stateName}</td>
                <td>{each.districtName}</td>
                <td>{each.mandalName}</td>
                <td>{each.cropStage}</td>
                <td>{each.cropAsPerApplication}</td>
                <td>{each.cropAsPerField}</td>
                <td>
                  {each.chmDate.split("T")[0].split("-").reverse().join("-")}
                </td>
                <td
                  className="view-form"
                  onClick={() => {
                    getEachCHMInfo(each.chmID, each.cropStageId);
                  }}
                >
                  View Form
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default CHMTable;
