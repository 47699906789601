import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  isLoading: false,
  storeCHMGetStatesData: {},
  storeCHMGetDistrictsData: {},
  storeCHMGetMandalData: {},
  storeCHMGetVillagesData: {},
  storeCHMGetSeasonsData: {},
  storeCHMGetCropsData: {},
  storeCHMPaginationData: {
    limit: 0,
    offset: 25,
    selectedLimit: 25,
  },
  storeCHMDashboardData: {},
  storeCHMDIdData: {},
  storeCHMImageOneData: {},
  storeCHMImageTwoData: {},
  storeCHMImageThreeData: {},
  storeCHMChartData: {},
  storeCHMIds: {},
  storeDashboardViewMapData: {},
  storeCHMExcelData: {},
  storeCHMExcelUploadStatus: 0,
  storeCHMExcelUploadDetails: {},
  storeCHMSurveyorData: {},
};

export const chmActivitySlice = createSlice({
  name: "chmActivity",
  initialState,
  reducers: {
    startLoadingCHM(state) {
      state.isLoading = true;
    },
    stopLoadingCHM(state) {
      state.isLoading = false;
    },
    handleCHMGetStatesDataSlice(state, action) {
      state.storeCHMGetStatesData = action.payload;
      state.isLoading = false;
    },
    handleCHMGetDistrictsDataSlice(state, action) {
      state.storeCHMGetDistrictsData = action.payload;
      state.isLoading = false;
    },
    handleCHMGetMandalDataSlice(state, action) {
      state.storeCHMGetMandalData = action.payload;
      state.isLoading = false;
    },
    handleCHMVillagesDataSlice(state, action) {
      state.storeCHMGetVillagesData = action.payload;
      state.isLoading = false;
    },
    handleCHMSeasonsDataSlice(state, action) {
      state.storeCHMGetSeasonsData = action.payload;
      state.isLoading = false;
    },
    handleCHMCropsDataSlice(state, action) {
      state.storeCHMGetCropsData = action.payload;
      state.isLoading = false;
    },
    handleCHMDashboardDataSlice(state, action) {
      state.storeCHMDashboardData = action.payload;
      state.isLoading = false;
    },
    handleCHMInfoByIdDataSlice(state, action) {
      state.storeCHMDIdData = action.payload;
      state.isLoading = false;
    },
    handleCHMGetImagesOneDataSlice(state, action) {
      state.storeCHMImageOneData = action.payload;
      state.isLoading = false;
    },
    handleCHMGetImagesTwoDataSlice(state, action) {
      state.storeCHMImageTwoData = action.payload;
      state.isLoading = false;
    },
    handleCHMGetImagesThreeDataSlice(state, action) {
      state.storeCHMImageThreeData = action.payload;
      state.isLoading = false;
    },
    handleCHMGisChartDataSlice(state, action) {
      state.storeCHMChartData = action.payload;
      state.isLoading = false;
    },
    handleCHMIdsDataSlice(state, action) {
      state.storeCHMIds = action.payload;
      state.isLoading = false;
    },
    handleCHMDashboardViewMapDataSlice(state, action) {
      state.storeDashboardViewMapData = action.payload;
      state.isLoading = false;
    },
    handleCHMExcelDownloadDataSlice(state, action) {
      state.storeCHMExcelData = action.payload;
      state.isLoading = false;
    },
    handleCHMExcelUploadStatusDataSlice(state, action) {
      state.storeCHMExcelUploadStatus = action.payload;
      state.isLoading = false;
    },
    handleCHMExcelUploadDetailsDataSlice(state, action) {
      state.storeCHMExcelUploadDetails = action.payload;
      state.isLoading = false;
    },
    handleCHMSurveyorDataSlice(state, action) {
      state.storeCHMSurveyorData = action.payload;
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  startLoadingCHM,
  handleCHMGetStatesDataSlice,
  handleCHMGetDistrictsDataSlice,
  handleCHMGetMandalDataSlice,
  handleCHMVillagesDataSlice,
  handleCHMSeasonsDataSlice,
  handleCHMCropsDataSlice,
  handleCHMDashboardDataSlice,
  handleCHMInfoByIdDataSlice,
  handleCHMGetImagesOneDataSlice,
  handleCHMGetImagesTwoDataSlice,
  handleCHMGetImagesThreeDataSlice,
  handleCHMGisChartDataSlice,
  handleCHMIdsDataSlice,
  handleCHMDashboardViewMapDataSlice,
  handleCHMExcelDownloadDataSlice,
  handleCHMExcelUploadStatusDataSlice,
  handleCHMExcelUploadDetailsDataSlice,
  handleCHMSurveyorDataSlice,
  stopLoadingCHM,
  hasError,
} = chmActivitySlice.actions;
export default chmActivitySlice.reducer;
